import React from 'react';
import Header from '../shared/Header'

export function html() {
    return (
        <React.Fragment>
            <Header>Documentos</Header>
            <div className="Content">
                <div>
                    <p>
                        Todo el flujo en Firmamex empieza con un Documento. Existen varias acciones que se pueden hacer con un Documento:
                </p>
                    <ul>
                        <a href="/docs/documento/subir"><li>Subir documento</li></a>
                        <a href="/docs/documento/stickers"><li>Agregar Sticker(s) al documento</li></a>
                        <a href="/docs/documento/flujos"><li>Agregar un flujo de firmado al documento</li></a>
                        <a href="/docs/documento/qr"><li>Agregar un código QR al documento</li></a>
                        <a href="/docs/documento/expedientes"><li>Crear un expediente de documentos</li></a>
                        <a href="/docs/documento/templates"><li>Crear templates de documentos</li></a>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default { html }
