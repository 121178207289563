import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'
import BlueSection from '../../shared/BlueSection';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
        {`
// Agregando un Sticker (firmante) al documento enviado

Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

{
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        "authority": "Vinculada a Correo Electronico por Liga",
        "stickerType": "rect",
        "dataType": "email",
        "imageType":"stroke",
        "data": "firmante@dominiodelfirmante.com",
        "page": 0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        }
    }]
}
        `}
        </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
// Agregando un Sticker (firmante) al documento enviado

const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        "authority": "Vinculada a Correo Electronico por Liga",
        "stickerType": "rect",
        "dataType": "email",
        "imageType":"stroke",
        "data": "firmante@dominiodelfirmante.com",
        "page": 0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        }
    }]
});

`}
        </Code>
    }
}


export function Response() {
    return <Code lang="javascript">
        {
`
{
    "document_ticket":"c6279a7c-b3d5-4c71-b417-4687a4f38914",
    "document_flow":false,
    "sticker_count":1,
    "stickers":[
        {
            "sticker_id":"qv3ZzAL3w",
            "sticker_index":0,
            "sticker_email":"firmante@dominiodelfirmante.com",
            "sticker_data":"firmante@dominiodelfirmante.com",
            "sticker_page":0,
            "sticker_coordinates":{
                "lx":330.0,
                "ly":300.0,
                "ux":530.0,
                "uy":400.0
            },
            "sticker_type":"EMAIL",
            "sticker_image_type":"hash",
            "sticker_authorities":[
                {
                "authority_name":"Vinculada a Correo Electrónico"
                }
            ]
        }
    ],
    "document_url":"https://firmamex.com/pdf/c6279a7c-b3d5-4c71-b417-4687a4f38914/?child=true&inv=true"
}
`
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Utilizando la URL de un documento en línea</Subtitle>
            <div className="Content">
            <p>
                Si tu documento puede ser accedido públicamente a través de una URL, puedes usar esta misma URL para enviar
                el documento a Firmamex.
            </p>
            <p>
                Para subir el documento llamamos al método <code>request</code> del SDK y le pasamos un objeto que contenga
                un object key llamado url_doc, que a su vez contendrá la URL y nombre del documento.
            </p>
            <p>
                <strong>url:</strong> URL del documento en línnea que se quiere enviar.
            </p>
            <p>
                <strong>name:</strong> Nombre que se le quiere asignar al documento en el servidor de Firmamex.
            </p>

            <p>
                Para agregar un firmante la forma mas sencilla de hacerlo es con un <a href="/docs/documento/stickers">Sticker</a>.
            </p>
            <BlueSection>
            Un sticker es un espacio designado en tu documento para que el firmante pueda firmar el documento.
            </BlueSection>
            <p>
                En el objeto Stickers agregamos información para especificar el tipo de autoridad que validará la firma, email del firmante en donde se encuentra la firma ubicada
                físicamente en el documento, entre otros datos. Para saber más acerca de Stickers, <a href="/docs/documento/stickers">Da click aquí</a>.
            </p>
            <p>
                En este ejemplo, se enviará el documento a Firmamex y además se le enviará un correo al firmante para notificarle que tiene
                un documento para firma, el correo contendrá la URL para acceder a firmar el documento.
            </p>
            <BlueSection>
                <strong>Importante:</strong> No en todos los casos se enviará automáticamente un correo al firmante. Para mas información acerca de esto
                por favor revisa la sección de <a href="/docs/documento/stickers">Stickers</a> y <a href="/docs/documento/flujos">Flujos</a>.
            </BlueSection>
            <br />
            </div>
        </React.Fragment>
    )
}


export default { JS, html, Response }
