import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Main from './Main'
import DemoHtml from './DemoHtml'
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <Router>
        <Route path="/demoHtml" component={DemoHtml}></Route>
        <Route path="/docs" component={Main}></Route>
    </Router>    
  );
}

export default App;
