import Code from "../../shared/Code";
import React from "react";
import Subtitle from "../../shared/Subtitle";
import BlueSection from "../../shared/BlueSection";
import ExternalLink from "../../shared/ExternalLink";


export function JS({codeLang}) {
    switch (codeLang) {
        case 'http':
            return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /api/document/{ticket}/endorse

{
    endorsment: 'descripcion del endoso',
// endosante
    endorser: {
        "authority": "SAT",
        "stickerType": "line",
        "dataType": "rfc",
        "email": "test@firmamex.com",
        "data": "ARCX9012226P8",
        // el texto de descripción del endoso
        // se usará como imagen de firma
        "imageType": "endorsment",
        "page": 1,
        "rect": {
            "lx": 145,
            "ly": 496,
            "tx": 443,
            "ty": 610
        }
    },
// endosatario (opcional)
    endorsee: {
        "authority": "SAT",
        "stickerType": "line",
        "dataType": "rfc",
        "email": "test2@firmamex.com",
        "data": "BACX9012226P8",
        "imageType": "stroke",
        "page": 1,
        "rect": {
            "lx": 145,
            "ly": 296,
            "tx": 443,
            "ty": 410
        }
    }
}
                `}
            </Code>
        default:

            return <Code lang="javascript">
                {
                    `
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const frmxId = 'firmamex id de documento firmado por completo';
const endorsmentDescription = 'Se hace constar que, en esta fecha, XX, S.A.P.I. de C.V., como tenedor, cedió el presente pagaré de fecha XXXX, suscrito por XXXX, por la totalidad del monto amparado por el mismo al que esta anotación va adherida.'

const response = await services.endorse(frmxId, 
    endorsmentDescription, 
    // endosante
    {
        "authority": "SAT",
        "stickerType": "line",
        "dataType": "rfc",
        "email": "test@firmamex.com",
        "data": "ARCX9012226P8",
        // el texto de descripción del endoso
        // se usará como imagen de firma
        "imageType": "endorsment",
        "page": 1,
        "rect": {
            "lx": 145,
            "ly": 496,
            "tx": 443,
            "ty": 610
        }
    },
    // endosatario (opcional)
    {
        "authority": "SAT",
        "stickerType": "line",
        "dataType": "rfc",
        "email": "test2@firmamex.com",
        "data": "BACX9012226P8",
        "imageType": "stroke",
        "page": 1,
        "rect": {
            "lx": 145,
            "ly": 296,
            "tx": 443,
            "ty": 410
        }
    }
    );

    `
                }
            </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `{
    status: "success",
}
`
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Endosar documento</Subtitle>
            <div className="Content">
                <p>
                    Para endosar un documento, puedes hacer que un documento ya firmado,
                    pase de nuevo por un flujo de firma. Al ser completado, se le incluirá un
                    sello de tiempo adicional de la NOM151.
                </p>
                <BlueSection>
                    Recomendamos que cualquier documento que se vaya a endosar, cuente con un código QR
                    de Firmamex para poder ser verificado en cualquier momento, incluso si el documento
                    se ha impreso
                </BlueSection>
                <p>
                    Un documento para endoso recibe los siguientes parámetros:
                </p>
                <ul>
                    <li>
                        <strong>endorsment</strong>: Descripción del endoso que se está realizando
                    </li>
                    <li>
                        <strong>endorser</strong>: <ExternalLink
                        href="/docs/documento/stickers/api">Sticker</ExternalLink> para firma por el endosante
                    </li>
                    <li>
                        <strong>endorsee</strong>: <ExternalLink
                        href="/docs/documento/stickers/api">Sticker</ExternalLink> para firma por el endosatario
                    </li>
                </ul>
                <p>
                    Las opciones de los firmantes son las mismas que se usan para enviar un documento a firma
                    normalmente, pero un flujo de endoso tiene las siguientes diferencias respecto a un flujo de firma
                    normal:
                </p>
                <ul>
                    <li>
                        Únicamente se acepta en documentos que ya fueron firmados por completo
                    </li>
                    <li>
                        Permite especificar "endorsment" como trazo de firma para el endosante. Lo que
                        hará que cualquiera pueda ver el endoso directamente sobre el documento.
                        Esto no es obligatorio, y si lo prefieres, puedes usar cualquier otra
                        opción de las disponibles en "imageType"
                    </li>
                    <li>
                        El endoso quedará guardado en los datos de la firma del endosante, y se podrá
                        verificar en cualquier lector PDF
                    </li>
                </ul>
                <div>
                    Después de agregar los firmantes para el endoso, puedes enviar las invitaciones a firma usando las
                    opciones de <ExternalLink href="/docs/acciones/flujo">flujos</ExternalLink>
                </div>
            </div>
        </React.Fragment>
    )
}

export default {JS, html, Response}
