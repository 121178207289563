import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {FormGroup, Input, Label, Form, Button} from 'reactstrap'
import styles from './DemoHtml.module.css'

export default function DemoHtml() {

    const [rfc, setRfc] = useState('');
    const [url, setUrl] = useState(null)
    const [signed, setSigned] = useState(false)
    const [working, setWorking] = useState(false);

    useEffect(() => {
        function receiveMessage(event) {
            if (event.origin === "https://firmamex.com") {
                const {result} = JSON.parse(event.data)
                setSigned(result)
            }
        }

        window.addEventListener('message', receiveMessage, false)
        return () => window.removeEventListener('message', receiveMessage);
    })

    function handleChangeRfc(event) {
        setRfc(event.target.value.toUpperCase());
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true);
        const response = await axios.post('https://api.sandbox.firmamex.com/api/rfc', {
        // const response = await axios.post('http://localhost:3002/api/rfc', {
            rfc
        })
        setUrl(response.data.url);
    }

    function handleClickExpress(event) {
        const width = window.innerWidth/2; 
        const height = window.innerHeight - 400;
        const options = "dependent=yes,scrollbars=yes,rezisable=no,width="+width+",height="+height+",top="+100+",left="+height/2;
        window.open(url.replace('/pdf/', '/express/'),"signmage_window",options)
    }

    function handleClickFirmar(event) {
        const width = window.innerWidth; 
        const height = window.innerHeight;
        const options = "dependent=yes,scrollbars=yes,rezisable=yes,width="+width+",height="+height+",top="+100+",left="+height/2;
        window.open(url + '&callback=firmamexCallback',"signmage_window",options)
    }    

    return (
        <div>
            <div>
                {
                    signed ? 
                    <p style={{fontWeight:'bold', textAlign:'center'}}>Documento firmado exitosamente</p>
                    :
                    !url ? 
                    <Form onSubmit={handleSubmit} className={styles.form}>                    
                        <p>
                            Prueba generar un documento para firmar con tu certificado del SAT
                        </p>
                        <FormGroup>
                            <Label>Ingresa tu RFC</Label>
                            <Input type="text" value={rfc} onChange={handleChangeRfc}></Input>
                        </FormGroup>
                        {
                            working ? 
                            <p>Estamos generando tu documento personalizado</p>
                            :
                            <Button type="submit" color="primary" disabled={!rfc}>Continuar</Button>
                        }                        
                    </Form>
                    :
                    <div className={styles.document}>
                        <p>
                            Documento generado para {rfc}
                        </p>
                        <p>
                            Este documento contiene información de muestra, pero al integrarse a tu servicio tu decides lo que debe contener                            
                        </p>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis condimentum molestie. Fusce ex tortor, scelerisque vitae pulvinar at, vehicula id metus. Mauris finibus eros libero, vel consequat risus sagittis eget. Aenean accumsan dolor eu purus scelerisque, eu varius leo pretium. Vestibulum ullamcorper erat a dolor elementum interdum sed eget lectus. Aliquam erat volutpat. Phasellus tellus quam, egestas ut mi in, euismod iaculis diam. Suspendisse vel congue lacus. In eget metus in lectus tristique convallis. Vestibulum dolor velit, rhoncus non cursus ut, laoreet ac leo.
                        </p>
                        <p>
                        Etiam molestie laoreet tortor ac pharetra. Etiam congue, sem sit amet venenatis aliquet, ligula nisl mollis tortor, tristique euismod eros urna id mauris. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus vitae faucibus est, vitae vestibulum ligula. Maecenas convallis ullamcorper eros eget viverra. Sed eget mauris auctor, aliquam quam non, feugiat mi. Curabitur auctor lectus et lectus porta pharetra sit amet non nisi. Nunc posuere nunc et eleifend gravida. Fusce ultricies nisl at lorem rutrum dictum. Donec imperdiet efficitur est, eget efficitur ligula semper id. Maecenas interdum eros quis ultricies consectetur. Suspendisse et magna luctus, rhoncus ligula sit amet, suscipit urna. Etiam id viverra sem, a laoreet magna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec dignissim diam velit, nec aliquam lacus varius ut. Vivamus euismod erat sit amet nisi vestibulum, nec fringilla mi euismod.
                        </p>
                        <p>
                        Curabitur vitae arcu ut felis auctor pellentesque at non nulla. Sed dictum ultrices ante vitae vestibulum. Sed nisi neque, interdum sit amet mauris aliquet, venenatis imperdiet eros. Proin a leo euismod sapien fringilla lobortis id in mauris. Quisque in est accumsan, pellentesque ex id, lobortis massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse commodo vestibulum lectus, viverra sollicitudin orci varius vel. Vestibulum fringilla molestie metus, et varius lectus pulvinar at. Vivamus a pulvinar diam. Vivamus non feugiat justo. Mauris non tortor vel dolor cursus egestas. Donec quis porta mi. Vivamus pharetra nisi vitae scelerisque convallis.
                        </p>
                        <br/>
                        <div className={styles.signButton}>                            
                            <Button color="primary" style={{marginBottom:'40px'}}
                                onClick={handleClickFirmar}>Firmar</Button>                            
                            <p>
                                Realiza la firma dentro del portal de Firmamex. Ve el 
                                documento final renderizado y el trazo de firma sobre el documento
                            </p>
                        </div>
                        <div className={styles.signButton}>                            
                            <Button color="primary" onClick={handleClickExpress}>Firma express</Button>
                            <p>
                                Realiza una firma rápida sin entrar al portal de Firmamex
                            </p>
                        </div>                                                
                    </div>
                }
                
            </div>
        </div>
    )

}